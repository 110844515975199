import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
import { toast, ToastContainer } from 'react-toastify'
import Markdown from "@coursemaker/gatsby-theme-coursemaker/src/helpers/StrapiMarkdown/Markdown";
import ReactMarkdown from "react-markdown";
import LayoutLecture from "@coursemaker/gatsby-theme-coursemaker/src/components/layout-lecture";
import Breadcrumbs from "@coursemaker/gatsby-theme-coursemaker/src/components/course-breadcrumbs";
import Video from "../components/video";
import Download from "../components/download";
import FastAPIClient, { formatError } from "../../../../auth/fast_api_auth";
import { isAuthorized } from "../auth/auth";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { bakeLocalStorage, readLocalStorage } from "@coursemaker/gatsby-theme-coursemaker/src/helpers/storage";
import {navigate} from "gatsby";
import "katex/dist/katex.min.css"


const CodeEditor = (props) => {
    if (typeof window !== 'undefined') {
      const CodeEditor = require("@coursemaker/school-components").CodeEditor
      return <CodeEditor {...props}/>
    }
    return <></>;
}


const Lecture = ({ pageContext = {} }) => {
  const currentCourse = pageContext.course;
  const lecture = pageContext.lecture;

  const schoolExternalId = pageContext.school.external_id;
  const client = new FastAPIClient(schoolExternalId);

  const [codeSubmissionTemplate, setCodeSubmissionTemplate] = useState(lecture.code_submission_template)

  useEffect(() => {
    if (lecture?.allow_preview === true){
      return;
    }
    if (!isAuthorized(pageContext.course.id)) {
      navigate(`/courses${pageContext.course.slug}checkout`);
    }
  })

  let allLectures = pageContext?.allLectures;
  let nextLecture = pageContext?.nextLecture;
  let previousLecture = pageContext?.previousLecture;

  let schoolThemeStyle = pageContext.school?.schoolThemeStyle;
  if (!schoolThemeStyle) {
    schoolThemeStyle = {
      primaryColor: 'blue',
      secondaryColor: 'blue',
    };
  }

  let nextLectureSlug = '../curriculum';
  if (nextLecture && nextLecture.hasOwnProperty('order') && nextLecture.order !== null){
    nextLectureSlug = `${nextLecture.id}${nextLecture.order}`;
  } else if (nextLecture && !nextLecture?.order) {
    nextLectureSlug = `${nextLecture.id}`;
  }

  let previousLectureSlug = '../curriculum';
  if (previousLecture && previousLecture.hasOwnProperty('order') && previousLecture.order !== null){
    previousLectureSlug = `${previousLecture.id}${previousLecture.order}`;
  } else if (previousLecture && !previousLecture?.order) {
    previousLectureSlug = `${previousLecture.id}`;
  }

  let lecture_body;
  if (lecture.body) {
    // local source
    lecture_body = <MDXRenderer>{lecture.body}</MDXRenderer>;
  } else if (lecture.body_markdown) {
    // strapi
    lecture_body = (
        <div>
            <Markdown source={lecture.body_markdown} />
        </div>
    );
  } else {
    lecture_body = null;
  }

  const addLectureToComplete = async (lecture) => {
    let state = readLocalStorage(currentCourse.slug);
    let newState = {
      items: [...((state && state?.items) || [])],
    };

    const exists = newState?.items?.some((item) => item?.id === lecture?.id);

    if (exists) {
      newState.items = newState?.items.map((item) =>
        item?.id === lecture?.id
          ? {
              ...item,
            }
          : item
      );
    } else {
      newState.items = [...newState.items, { id: lecture?.id }];
    }

    bakeLocalStorage(currentCourse.slug, newState);
  };
  return (
      <>
    <LayoutLecture
      schoolThemeStyle={schoolThemeStyle}
      pageContext={pageContext}
      lecture={lecture}
      lectureList={allLectures}
      totalLectures={allLectures.length}
      currentCourse={currentCourse}
    >
      {/* video */}
      {<Video lecture={lecture} />}

      {/* course header */}
      <div className="pt-5 border-b border-gray-300">
        <div className="container lg:max-w-full">
          {
            <Breadcrumbs
              school={pageContext.school}
              course={currentCourse}
              lecture={lecture}
            />
          }
          <div className="items-end justify-between pt-4 pb-6 lg:flex">
            <div>
              <h2 className="leading-tight">{lecture.title}</h2>
            </div>

            {/* .controls */}
            <div className="flex mt-5 controls space-x-6 lg:mt-0">
              {previousLecture && (
                <Link to={`../${previousLectureSlug}`} className="btn btn-gray">
                  Previous
                </Link>
              )}
              {nextLecture && (
                <Link
                  onClick={async () => {
                    await addLectureToComplete(nextLecture);
                  }}
                  to={`../${nextLectureSlug}`}
                  className={`btn bg-${schoolThemeStyle?.primaryColor}-500 text-white`}
                >
                  Next
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {<Download lecture={lecture} />}
      {/* course content */}
      {(lecture_body !== null || lecture?.code_submission_template?.source_code) && (
      <div className="w-full py-12 mx-auto lg:py-16 lg:w-9/12">
        <div className="container">
          <div className="font-light leading-relaxed text-gray-700 description space-y-8 lg:w-11/12">
            {lecture_body}
            {lecture?.code_submission_template?.source_code && (
              <div className="CodeEditorWrapper">
                <CodeEditor
                  codeSubmissionTemplate={codeSubmissionTemplate}
                  setCodeSubmissionTemplate={setCodeSubmissionTemplate}
                  createCodeSubmission={(sourceCode, expectedOutput, codeExecutionBackend, languageID) => client.createCodeSubmission(sourceCode, expectedOutput, codeExecutionBackend, languageID)}
                  getCodeSubmission={(token, codeExecutionBackend) => client.getCodeSubmission(token, codeExecutionBackend)}
                  getSupportedLanguages={() => client.getSupportedLanguages()}
                  displayError={(err) => toast.error(formatError(err))}
                  editable={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      )}
    </LayoutLecture>
    <ToastContainer />
    </>
);
};

export default Lecture;