/** @jsx jsx */
import { jsx } from "theme-ui";
import FastAPIClient from "../../../../auth/fast_api_auth";
import {useEffect, useState} from "react";
import 'shaka-player/dist/controls.css';

const ShakaPlayer = (props) => {
  if (typeof window !== 'undefined') {

    const ShakaPlayer = require('shaka-player-react')
    return <ShakaPlayer {...props}/>
  }
  return null;
}

const Video = ({ lecture, ...props }) => {
  let videoURL = lecture.video_id ? lecture.video_id : null;
  let [signedURL, setSignedURL] = useState(0)

  const fetchSignedURL = async function(videoURL) {
    if (videoURL == null){
      return videoURL
    }

    let client = new FastAPIClient();
    let signedVideoURL = await client.signVideoURL(videoURL)
    return signedVideoURL?.token
  }

  useEffect(() => {
    fetchSignedURL(videoURL)
      .then(data => {
        setSignedURL(data)
      })
    },[])

  if (videoURL == null){
    return null;
  }

  if (signedURL != 0){
    let url = `https://videodelivery.net/${signedURL}/manifest/video.mpd?clientBandwidthHint=3`
    return (
      <div className="bg-black video-wrapper" style={{display: "contents"}}>
        <ShakaPlayer autoPlay src={url} />
      </div>
    );
  } else {
    return null
  }
};

export default Video;
